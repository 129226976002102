import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import LoadingButton from '../../global/components/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { IRegisterSchool, IRegisterUser } from '../dto/AuthDto';

interface ThirdStepProps {
    signString: string,
    setSignString: (signStr: string) => void,
    registerLoading: boolean,
    finishReg: () => void,
    nextStep: (step: number) => void,
    registerFinished: boolean,
    schoolForm: IRegisterSchool,
    userForm: IRegisterUser
}

const schoolFieldNames = {
    bin: 'БИН',
    title: 'Название автошколы',
    director: 'ФИО Директора',
    director_iin: 'ИИН Директора',
    email: 'Электронный адрес автошколы',
    region: 'Регион',
    locality: 'Населенный пункт',
    address: 'Адрес',
    phone1: 'Номер телефона (основной)',
    phone2: 'Номер телефона (дополнительный)',
};

// Маппинг названий на русский язык для пользователей
const userFieldNames: {[key in keyof IRegisterUser]?: string} = {
    iin: 'ИИН специалиста',
    first_name: 'Имя специалиста',
    last_name: 'Фамилия специалиста',
    patronymic: 'Отчество специалиста',
    email: 'Электронный адрес специалиста',
    phone: 'Телефон',
};

const InfoTable: React.FC<{ data: any; fieldNames: { [key: string]: string } }> = ({ data, fieldNames }) => (
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
              {Object.keys(fieldNames).map((key) => (
                <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                        {fieldNames[key]}
                    </TableCell>
                    <TableCell align="right">{Array.isArray(data[key]) ? data[key].join(', ') : data[key]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
      </Table>
  </TableContainer>
);

const ThirdStep = ({signString, setSignString, registerLoading, finishReg, nextStep, registerFinished, schoolForm, userForm} : ThirdStepProps) => {

    const navigate = useNavigate();

    const snackbar = useSnackbarStore((store) => store);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [messages, setMessages] = useState<string[]>([]);
    const [callback, setCallback] = useState<string | null>(null);

    const connectNCAWebSocket = () => {
        const websocket = new WebSocket('wss://127.0.0.1:13579/');

        websocket.onopen = () => {
            snackbar.infoMessage('Соединение с NCALayer успешно подключено');
        }

        websocket.onmessage = (event) => {
            setMessages(prevMessages => [...prevMessages, event.data]);

            const result = JSON.parse(event.data);

            if (result) {
                const code = result.code;
                const message = result.message;
                const responseObject = result.responseObject;

                console.log(`responseObject:`)
                console.log(responseObject)

                if (responseObject) {
                    setSignString(responseObject)
                }
            }
        }

        websocket.onerror = (event) => {
            snackbar.errorMessage('Не удалось установить соединение с NCALayer. Включите NCALayer и попробуйте попытку')
            if (window.confirm('Не удалось установить соединение с NCALayer. Нажмите OK для повторной попытки')) {
                connectNCAWebSocket();
            }
        }

        websocket.onclose = () => {
            console.log('WebSocket connection closed');
            setWs(null); // Обновляем состояние, указывая на закрытие соединения
        };

        setWs(websocket ? websocket : null); // Обновляем состояние с новым экземпляром WebSocket
    };

    useEffect(() => {
        connectNCAWebSocket();
    }, [])

    function signXml(storageName : string, keyType : string, xmlToSign : string, callBack : string) {
        var signXml = {
            "module": "kz.gov.pki.knca.commonUtils",
            "method": "signXml",
            "args": [storageName, keyType, xmlToSign, "", ""]
        };
        setCallback(callBack);
        ws?.send(JSON.stringify(signXml));
    }

    const xmsSignCall = (xml: any) => {
        // Здесь будет логика вызова
        const storageType = "PKCS12";
        const xmlToSign = xml;

        signXml(storageType, "SIGNATURE", xmlToSign, "signXmlBack");
    };

    const returnToSecondStep = () => {
        setSignString('');
        nextStep(1);
    }

    return (
        <Box className={'register-form'} flexDirection={'column'} alignItems={'center'}>

            <Stack flexDirection={'row'} gap={'100px'} sx={{mt: 5}}>
                <Stack flexDirection={'column'} gap={2}>
                    <h2>Информация о школе</h2>
                    <InfoTable data={schoolForm} fieldNames={schoolFieldNames} />
                </Stack>

                <Stack flexDirection={'column'} gap={2}>
                    <h2>Информация о пользователе</h2>
                    <InfoTable data={userForm} fieldNames={userFieldNames} />
                </Stack>
            </Stack>

            <Stack flexDirection={'row'} justifyContent={'center'} gap={'20px'} sx={{mt: 2}}>
                <LoadingButton
                  onClick={() => {returnToSecondStep()}}
                  color={'info'}
                  variant={'outlined'}
                  sx={{width: '207px', height: '56px'}}
                  loading={registerLoading}
                >
                    Назад
                </LoadingButton>
                <LoadingButton
                  onClick={() => {ws ? (!signString ? xmsSignCall('<signData>Согласен с отправляемыми данными</signData>') : snackbar.errorMessage('Данные уже подписаны')) : connectNCAWebSocket()}}
                  variant={'contained'}
                  sx={{width: '207px', height: '56px'}}
                  loading={registerLoading}
                  disabled={!ws}
                >
                    Подписать
                </LoadingButton>
                {signString && (<LoadingButton
                    onClick={() => {finishReg()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                    color={'secondary'}
                >
                    Отправить заявку
                </LoadingButton>)}
            </Stack>

        </Box>
    );
};

export default ThirdStep;
