import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, InputBase, Stack, Typography } from '@mui/material';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import { getCabinets, getGroupById, getSectionsAndTopicByCategory, submitClass } from '../../actions/api';
import { useSnackbarStore } from '../../../global/store/SnackbarStore';
import LoadingButton from '../../../global/components/LoadingButton';

interface SubmitClassModalProps {
  groupInfo: any,
  open: boolean,
  setOpen: (st: boolean) => void,
  fetchGroupInfo: () => void,
  fetchClasses: (id: number) => void
}

const SubmitClassModal = ({groupInfo, open, setOpen, fetchGroupInfo, fetchClasses} : SubmitClassModalProps) => {

  const snackbar = useSnackbarStore((state) => state);

  const [sectopsList, sectopsLoading, , fetchSecTops] = useLoadingHook(getSectionsAndTopicByCategory);
  const [cabinetsList, cabinetsLoading, , fetchCabinets] = useLoadingHook(getCabinets);

  const [submitResult, submitLoading, submitError, submit] = useLoadingHook(submitClass);

  const [topics, setTopics] = useState<any>([]);
  const [classDto, setClassDto] = useState<any>({
    group_id: groupInfo.id,
    school_id: groupInfo.school_id,
    type: 'theory',
    teacher_id: null,
    driver_id: null,
    master_id: null,
    car_id: null,
    cabinet_id: null,
    student_id: null,
    start_date_time: '',
    duration: 1,
    section_id: null,
    topic_id: null
  });

  useEffect(() => {
    fetchSecTops(groupInfo.category_id);
    fetchCabinets(groupInfo.school_id, true);
  }, []);

  const handleSection = (section: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        section_id: section.id,
        topic_id: null,
        type: section.type,
        teacher_id: null,
        driver_id: null,
        master_id: null,
        car_id: null,
        cabinet_id: null,
        student_id: null,
        start_date_time: ''
      }
    });
    setTopics(section.topics);
  };

  const handleTopic = (topic: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        topic_id: topic.id
      }
    });
  };

  const handleCabinet = (cabinet: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        cabinet_id: cabinet.id
      }
    });
  };

  const handleTeacher = (teacher: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        teacher_id: teacher.id
      }
    });
  };

  const handleDriver = (driver: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        driver_id: driver.id
      }
    });
  };

  const handleMaster = (master: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        master_id: master.id
      }
    });
  };

  const handleTransport = (transport: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        car_id: transport.car.id
      }
    });
  };

  const handleStudent = (student: any) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        student_id: student.id
      }
    });
  };

  const handleDateTime = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setClassDto((prev: any) => {
      return {
        ...prev,
        start_date_time: e.target.value
      }
    });
  };

  const handleDuration = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    if (Number(e.target.value) < 1) {
      snackbar.errorMessage('Длительность занятия должна быть не менее 1 академического часа');
      return;
    }

    if (Number(e.target.value) > 2) {
      snackbar.errorMessage('Длительность занятия не должна превышать 2 часа без перерыва');
      return;
    }

    setClassDto((prev: any) => {
      return {
        ...prev,
        duration: Number(e.target.value)
      }
    });
  };

  function convertDate(input: string): string {
    let date = new Date(input);

    // Форматируем дату в нужный вид
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    let day = String(date.getUTCDate()).padStart(2, '0');
    let hours = String(date.getUTCHours()).padStart(2, '0');
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');
    let seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  useEffect(() => {
    if (submitResult) {
      updateClasses();
    }
  }, [submitResult]);

  useEffect(() => {
    if (submitError) {
      snackbar.errorMessage(submitError?.response?.data?.message ? submitError?.response?.data?.message : 'Непредвиденная ошибка')
    }
  }, [submitError]);

  const updateClasses = async () => {
    snackbar.successMessage('Занятие успешно выставлено');
    await fetchGroupInfo();
    fetchClasses(Number(groupInfo.id));
    setClassDto({
      group_id: groupInfo.id,
      school_id: groupInfo.school_id,
      type: 'theory',
      teacher_id: null,
      driver_id: null,
      master_id: null,
      car_id: null,
      cabinet_id: null,
      student_id: null,
      start_date_time: '',
      duration: 1,
      section_id: null,
      topic_id: null
    });
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      maxWidth={'xl'}
      fullWidth={true}
    >
      <DialogTitle>
        Планирование нового занятия
      </DialogTitle>
      <DialogContent>
        <Box flexDirection={'column'}>
          <Stack flexDirection={'column'}>
            <Typography variant={'h6'}>Выберите учебный раздел (обязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              {sectopsList?.map((section: any, index: number) => {
                return (
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleSection(section)}}
                    disabled={classDto.section_id === section.id}
                    key={section.id}
                  >
                    {`${index + 1} ${section.title_ru}`}
                  </Button>
                )
              })}
            </Stack>
          </Stack>

          {topics.length > 0 && (<Stack flexDirection="column">
            <Typography variant={'h6'}>Выберите учебную тему (обязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              {topics?.map((topic: any, index: number) => {
                return (
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleTopic(topic)}}
                    disabled={classDto.topic_id === topic.id}
                    key={topic.id}
                  >
                    {`${index + 1}. ${topic.title_ru} | ${topic.hours} ч.`}
                  </Button>
                );
              })}
            </Stack>
          </Stack>)}

          {/* ----- ТЕОРЕТИЧЕСКИЕ ЗАНЯТИЯ ----- НАЧАЛО */}
          {classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'theory' && (<Stack flexDirection="column">
            <Typography variant={'h6'}>Выберите кабинет и преподавателя (обязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              {cabinetsList?.map((cabinet: any, index: number) => {
                return (
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleCabinet(cabinet)}}
                    disabled={classDto.cabinet_id === cabinet.id}
                    key={cabinet.id}
                  >
                    {`${index + 1}. ${cabinet.address}`}
                  </Button>
                );
              })}
            </Stack>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleTeacher(groupInfo.teacher.teacher)}}
                    disabled={classDto.teacher_id === groupInfo.teacher.teacher.id}
                    sx={{py: 5}}
                  >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                      >
                        {groupInfo.teacher.teacher.last_name[0]}{groupInfo.teacher.teacher.first_name[0]}
                      </Avatar>
                      <Typography>{`${groupInfo.teacher.teacher.last_name} ${groupInfo.teacher.teacher.first_name}`}</Typography>
                    </Stack>
                  </Button>
            </Stack>
          </Stack>)}

          {classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'theory' && (<Stack flexDirection="column">
            <Typography variant={'h6'}>Выберите мастера производственного обучения (необязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              <Button
                variant={'outlined'}
                color={'info'}
                onClick={() => {handleMaster(groupInfo.master.master)}}
                disabled={classDto.master_id === groupInfo.master.master.id}
                sx={{py: 5}}
              >
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Avatar
                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                  >
                    {groupInfo.master.master.last_name[0]}{groupInfo.master.master.first_name[0]}
                  </Avatar>
                  <Typography>{`${groupInfo.master.master.last_name} ${groupInfo.master.master.first_name}`}</Typography>
                </Stack>
              </Button>
            </Stack>
          </Stack>)}
          {/* ----- ТЕОРЕТИЧЕСКИЕ ЗАНЯТИЯ ----- КОНЕЦ */}

          {/* ----- ПРАКТИЧЕСКИЕ ЗАНЯТИЯ ----- НАЧАЛО */}
          {classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'practice' && (<Stack flexDirection="column">
            <Typography variant={'h6'}>Выберите кабинет и преподавателя (обязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              {groupInfo.transport?.map((transport: any, index: number) => {
                return (
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleTransport(transport)}}
                    disabled={classDto.car_id === transport.car.id}
                    key={transport.car.id}
                  >
                    {`${index + 1}. ${transport.car.model} ${transport.car.plate_number}`}
                  </Button>
                );
              })}
            </Stack>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              <Button
                variant={'outlined'}
                color={'info'}
                onClick={() => {handleDriver(groupInfo.driver.driver)}}
                disabled={classDto.driver_id === groupInfo.driver.driver.id}
                sx={{py: 5}}
              >
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Avatar
                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                  >
                    {groupInfo.driver.driver.last_name[0]}{groupInfo.driver.driver.first_name[0]}
                  </Avatar>
                  <Typography>{`${groupInfo.driver.driver.last_name} ${groupInfo.driver.driver.first_name}`}</Typography>
                </Stack>
              </Button>
            </Stack>
          </Stack>)}

          {classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'practice' &&
            classDto.car_id && classDto.driver_id && (<Stack flexDirection="column">
            <Typography variant={'h6'}>Выберите студента (обязательно)</Typography>

            <Stack
              flexDirection={'row'}
              gap={3}
              sx={{p: 2, flexWrap: 'wrap'}}
            >
              {groupInfo.students?.map((student: any, index: number) => {
                return (
                  <Button
                    variant={'outlined'}
                    color={'info'}
                    onClick={() => {handleStudent(student)}}
                    disabled={classDto.student_id === student.id}
                    key={student.id}
                  >
                    {`${index + 1}. ${student.last_name} ${student.first_name}`}
                  </Button>
                );
              })}
            </Stack>
          </Stack>)}
          {/* ----- ПРАКТИЧЕСКИЕ ЗАНЯТИЯ ----- КОНЕЦ */}

          {classDto.section_id &&
            (classDto.topic_id || topics.length === 0) &&
            ((classDto.type === 'theory' && classDto.cabinet_id && classDto.teacher_id) || (classDto.type === 'practice' && classDto.car_id && classDto.driver_id && classDto.student_id)) &&
            (<Stack flexDirection="column">
              <Typography variant={'h6'}>Выберите дату, время и длительность проведения занятия</Typography>

              <Stack
                flexDirection={'row'}
                gap={3}
                sx={{p: 2, flexWrap: 'wrap'}}
              >
                <InputBase
                  onChange={handleDateTime}
                  type={'datetime-local'}
                  value={classDto.start_date_time}
                />
              </Stack>

              <Stack
                flexDirection={'row'}
                gap={3}
                sx={{p: 2, flexWrap: 'wrap'}}
              >
                <InputBase
                  onChange={handleDuration}
                  type={'number'}
                  value={classDto.duration}
                />
              </Stack>
            </Stack>)
          }

          {classDto.section_id &&
            (classDto.topic_id || topics.length === 0) &&
            ((classDto.type === 'theory' && classDto.cabinet_id && classDto.teacher_id) || (classDto.type === 'practice' && classDto.car_id && classDto.driver_id && classDto.student_id)) &&
            classDto.start_date_time &&
            (<Stack flexDirection="column">
              <Stack
                flexDirection={'row'}
                gap={3}
                sx={{p: 2, flexWrap: 'wrap'}}
              >
                <LoadingButton
                  variant={'contained'}
                  loading={submitLoading}
                  onClick={() => {submit({...classDto, start_date_time: convertDate(classDto.start_date_time)})}}
                >
                  Запланировать занятие
                </LoadingButton>
              </Stack>
            </Stack>)
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitClassModal;
