import React, { useEffect, useState } from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { findSchoolsByBIN, findStudentByIIN } from '../actions/api';
import StudentInfoBlock from '../components/StudentInfoBlock';
import SchoolInfoBlock from './SchoolInfoBlock';

const SearchByBINPage = () => {

  const snackbar = useSnackbarStore((state) => state);
  const [binValue, setBinValue] = useState<string>('');
  const [searchResults, searchResultsLoading, , executeSearch] = useLoadingHook(findSchoolsByBIN);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBinValue(e.target.value);
  }

  const getStudents = async () => {
    await executeSearch(binValue)
  }

  useEffect(() => {
    console.log(`SEARCHRESULTS`)
    console.log(searchResults)
  }, [searchResults])

  return (
    <Box>
      <Typography sx={{mt: 3}} variant={'h5'}><b>Поиск автошкол по БИН/ИИН</b></Typography>
      <Typography variant={'subtitle2'}>На данной странице возможно произвести поиск автошкол по БИН (юр. лица) или ИИН (физ. лица)</Typography>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{my: 2}}
      >
        <TextField
          name={'bin'}
          onChange={handleChange}
          value={binValue}
          placeholder={'БИН/ИИН'}
        />
        <Button
          variant={'contained'}
          onClick={() => {getStudents()}}
        >
          Поиск
        </Button>
      </Stack>

      <Stack flexDirection={'column'}>
        {!searchResults && !searchResultsLoading && (
          <Typography variant={'h6'}>Нет результатов</Typography>
        )}
        {searchResults && !searchResults.length && !searchResultsLoading && (
          <Typography variant={'h6'}>По данному БИН/ИИН отсутствует информация</Typography>
        )}
        {searchResultsLoading && (
          <CircularProgress />
        )}
        {searchResults && searchResults.map((school: any, index: number) => {
          return <SchoolInfoBlock schoolInfo={school} index={index} />
        })}
      </Stack>

    </Box>
  )
}
export default SearchByBINPage