import axiosInstance from '../../global/actions/main.api';
import { BACKEND_URL } from '../../global/actions/apiVariables';

export const getSchoolCabinets = (id: number, approved: string) => {
  return axiosInstance.get(`${BACKEND_URL}/cabinets/school/${id}?approved=${approved}`);
}

export const submitCabinet = (cabinetDto: any) => {
  return axiosInstance.post(`${BACKEND_URL}/cabinets/`, cabinetDto);
}
