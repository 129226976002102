import moment from 'moment-timezone';
import 'moment/locale/ru';

export function getDayDateTime(initialDate: Date) {
  moment.locale('ru');
  // Преобразование входной даты в момент времени с указанием часового пояса Asia/Yekaterinburg
  const formatted = moment(initialDate).tz('Asia/Yekaterinburg').format();

  const day = extractDayOfWeek(formatted);
  const date = extractDate(formatted);
  const time = extractTime(formatted);

  return {
    day,
    date,
    time
  }
}

// Функция для извлечения даты в формате "день месяц год"
export function extractDate(formatted: string): string {
  return moment(formatted).locale('ru').format('D MMMM YYYY');
}

// Функция для извлечения дня недели
export function extractDayOfWeek(formatted: string): string {
  return moment(formatted).locale('ru').format('dddd');
}

// Функция для извлечения времени в формате "часы:минуты"
export function extractTime(formatted: string): string {
  return moment(formatted).format('HH:mm');
}
