import React, { useEffect } from 'react';
import { Avatar, Box, Chip, Divider, Link, Stack, Typography } from '@mui/material';
import { formatDate, getName } from '../../global/helpers/text';
import { DirectionsCar } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import { GroupStatusChip } from './GroupStatusChip';

interface SchoolInfoBlockProps {
  schoolInfo: SchoolInfo,
  index: number
}

interface SchoolInfo {
  id: number;
  address: string;
  bin: string;
  director: string;
  locality: string;
  phone1: string;
  phone2: string;
  region: string;
  title: string
}

const gradesArray = [2,3,4,5];

const SchoolInfoBlock = ({schoolInfo, index} : SchoolInfoBlockProps) => {

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{my: 3, p: 2, borderRadius: '10px', border: '1px solid #cfcfcf'}}
      gap={2}
    >
      <Stack
        flexDirection={'row'}
        gap={5}
      >
        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Typography variant={'subtitle2'}>Результат №{index + 1}</Typography>

          <Stack flexDirection={'row'} alignItems={'center'}>
            <Avatar
              sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
            >
              <DirectionsCar />
            </Avatar>
            <Typography variant={'h6'}>
              {schoolInfo.title}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} gap={5}>
            <Stack flexDirection={'column'}>
              <Typography>
                Регион
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.region}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Населенный пункт
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.locality ? schoolInfo.locality : schoolInfo.region}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                БИН
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.bin}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider orientation={'vertical'} flexItem={true}/>

        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Stack flexDirection={'row'} gap={5}>
            <Stack flexDirection={'column'}>
              <Typography>
                ФИО директора
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.director}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Основной телефон
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.phone1}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Дополнительный телефон
              </Typography>
              <Typography variant={'h6'}>
                {schoolInfo.phone2 ? schoolInfo.phone2 : 'Н/Д'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};



export default SchoolInfoBlock;
